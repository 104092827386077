<template>
  <div>
    <div class="utils__title mb-3">
      <strong class="text-uppercase font-size-16">ログ検索</strong>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <section class="card">
          <div class="card-header">
            <h2>ログ</h2>
          </div>
          <div class="card-body">
            <div v-if="searchHistoryLoading" class="text-center">
              <a-spin tip="Loading..." />
            </div>
            <div v-if="!searchHistoryLoading">
              <span class="mr-5">フィルター</span>
              <a-form layout="inline" style="margin-bottom: 20px;">
                <a-form-model-item ref="user_id" label="ユーザーID" prop="user_id">
                  <a-input-number v-model="bodyData.user_id" :min="0" />
                </a-form-model-item>

                <a-form-model-item ref="target_id" label="ターゲットID" prop="target_id">
                  <a-input-number v-model="bodyData.target_id" :min="0" />
                </a-form-model-item>

                <a-form-model-item ref="target" label="ターゲット" prop="target">
                  <a-input v-model="bodyData.target" />
                </a-form-model-item>

                <a-form-model-item ref="max_id" label="IDの上限" prop="max_id">
                  <a-input-number v-model="bodyData.max_id" :min="0" />
                </a-form-model-item>

                <a-form-model-item ref="min_id" label="IDの下限" prop="min_id">
                  <a-input-number v-model="bodyData.min_id" :min="0" />
                </a-form-model-item>

                <a-form-model-item ref="sort" label="ソート" prop="sort">
                  <a-radio-group v-model="bodyData.sort">
                    <a-radio :value="0">昇順</a-radio>
                    <a-radio :value="1">降順</a-radio>
                  </a-radio-group>
                </a-form-model-item>

                <a-form-item>
                  <a-button @click="searchHistory" type="primary">検索</a-button>
                </a-form-item>
              </a-form>
              <a-table
                :columns="columns"
                :data-source="histories"
                :pagination="pagination"
                rowKey="id"
              >
                <span slot="created_at" slot-scope="created_at">{{ created_at | momentDateTime }}</span>
              </a-table>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'

export default {
  data() {
    return {
      searchHistoryLoading: false,
      histories: [],
      columns: [
        { title: 'ID', dataIndex: 'id' },
        { title: '操作日時', dataIndex: 'created_at', scopedSlots: { customRender: 'created_at' } },
        { title: 'ユーザーID', dataIndex: 'user_id' },
        { title: 'ユーザー名', dataIndex: 'user_name' },
        { title: 'ターゲットID', dataIndex: 'target_id' },
        { title: 'ターゲット', dataIndex: 'target' },
        { title: 'アクション', dataIndex: 'action' },
        { title: 'メモ', dataIndex: 'name' },
        { title: 'パス', dataIndex: 'path' },
      ],
      pagination: {
        defaultPageSize: 200,
      },
      selectedTargets: [],
      bodyData: {
        user_id: null,
        target_id: null,
        target: null,
        max_id: null,
        min_id: null,
        sort: 1,
      },
    }
  },
  filters: {
    momentDateTime: function (date) {
      return moment(date).format('YYYY/MM/DD HH:mm:ss')
    },
  },
  methods: {
    searchHistory() {
      const _this = this
      if (!this.bodyData['user_id'] && !this.bodyData['target_id']) {
        _this.$notification['error']({
          message: 'ユーザーIDかターゲットIDのどちらかは入力してください。',
        })
      } else {
        this.searchHistoryLoading = true

        const send = Vue.prototype.$api.send('post', 'histories/search', this.bodyData)
        send.then(response => {
          this.histories = response
          this.searchHistoryLoading = false
        })
          .catch(error => {
            if (error.status === 403) {
              _this.$notification['error']({
                message: error.data.data,
              })
            } else {
              for (var key in error.data.errors) {
                _this.$refs[key].validateState = 'error'
                _this.$refs[key].validateMessage = error.data.errors[key]
              }
            }
            this.searchHistoryLoading = false
            console.log(error)
          })
      }
    },
  },
}
</script>

<style>
.list-name {
  font-size: 1.5rem;
}
</style>
